.customTimes {
    position: absolute;
    right: 15px;
    top: 9px;
    font-size: 20px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    transition: .3s;
}
.customTimes::before {
    content: "\d7";
}
.customTimes:hover {
    color: red;
    transition: .3s;
    border-radius: 50%;
    background-color: #ccc;
}

.tabsContainer{
    display: flex;
}

.tab{
    cursor: pointer;
    padding:  0 10px;
    padding-bottom: 2px;
}

.activeTab{
    border-bottom: 1px solid #005B9A;
}
