.title {
    color: #005b9a;
    font-weight: 500;
    font-size: 16px;
    margin-left: 15px;
    margin-bottom: 5px;
}

.cardContainer {
    max-height: calc((121px + 15px + 14px) + (121px + 15px + 14px) + 29.5px);
    overflow-y: auto;
}
.cardContainer::-webkit-scrollbar-track {
    background: red;
    border-radius: 10px;
    margin-top: 25px;
}

.buildingOCardContainer2 {
    position: relative;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 0px 5px 0 rgb(29 53 87 / 10%); */
    box-shadow: rgb(128 138 157 / 4%) 0px 8px 32px, rgb(128 138 157 / 12%) 0px 1px 2px;
    width: 483px;
    padding: 12px !important;
    margin-bottom: 7px;
    margin-top: 7px;
    margin-left: 12px;
    margin-right: 5px;
    border: 1px solid #ebebeb;
}

.imageContainer {
    display: flex;
    justify-self: center;
    align-self: center;
}
.imageContainer img {
    width: 170.172px;
    height: 110px;
}

.informationCard {
    padding-left: 10px;
    padding-right: 10px;
    width: 65%;
}

.informationCard h3{
    font-size: 17px;
    color: #005b9a;
    text-align: start;
}

.informationCard ul{
    border-top: 1px solid #ccc;
    list-style: none;
    padding: 0;
}

.informationCard ul li{
    display: inline-block;
    margin: 0 5px
}

.informationCard ul li i{
    color: #fff;
    background-color: #005b9a;
    height: 26px;
    width: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 6px;
}


.buildingPriceWrapper{
    transition: left 0.3s ease 0s;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    position: absolute;
    left: -500px;
    top: 90px;
    background-color: #f5f6fa;
    min-width: 500px;
    width: 500px;
    z-index: 1;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    padding-right: 0;
}

.buildingPriceWrapperMobile{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    position: absolute;
    left: calc(-100vw + 30px);
    top: 0;
    background-color: rgb(240, 240, 240);
    width: calc(100vw - 30px);
    height: 100vh;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    padding-right: 0;
    transition: 0.3s;
    z-index: 1;
}

.statistacsWrapper{
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    padding-right: 15px;
    height: calc(100vh - 90px);
}

.kilonumberWithPercent{
    justify-content: center;
    display: flex;
    align-items: flex-end;
}

.resizer{
    position: absolute;
    top: 7%;
    right: -48px;
    /* transform: translateX(-50%); */
    /* transform: rotate(90deg); */
    width: 38px;
    padding: 5px;
    height: 40px;
    background-color: #00afb9;
    border-bottom: 0;
    text-align: center;
    font-size: 14px;
    display: flex;
    color: #fff;
    z-index: 998 !important;
    align-items: center;
    justify-content: center;
    transition: .5s;
    cursor: pointer;
    border: 2px solid #00afb9;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

.resizer:hover {
    color: #00afb9;
    background-color: #fff;
}

.sideHide{
    position: absolute;
    top: 25%;
    right: -54px;
    transform: rotate(90deg);
    width: 80px;
    padding: 5px;
    height: 30px;
    background-color: #00afb9;
    border-bottom: 0;
    text-align: center;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

.resizerReal{
    cursor: e-resize;
    position: absolute;
    top: 50%;
    right: -104px;
    transform: translateX(-50%);
    transform: rotate(90deg);
    width: 180px;
    padding: 5px;
    transition: .5s;
    height: 30px;
    background-color: #00afb9;
    border-bottom: 0;
    text-align: center;
    font-size: 14px;
    color: #fff;
    z-index: 998 !important;
}

.togglerIcon{
    cursor: pointer;
    color: #005B9A;
    padding: 2px;
    font-size: 15px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    background-color: rgb(247, 247, 247);
    border-radius: 5px;
}

.togglerIcon:hover{
    background-color: rgb(236, 236, 236);
}

.hidden{
    width: 50px;
    overflow: hidden;
}
.boxWrapper{
    position: relative;
    background-color: #fff;
    padding: 30px 8px 8px 8px;
    margin: 3px;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
}

.priceDiffCard{
    min-width: 350px;
}

.boxWrapperTitle{
    position: absolute;
    top: 5px;
    left: 0px;
    border-bottom: 1px solid rgb(219, 219, 219);
    width: 100%;
    color: rgb(92, 92, 92);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.analytacPrice{
    text-align: center;
    font-size: 20px;
}

.analytacPercent{
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 13px;
}

.selectDate{
    cursor: pointer;
}

.analytacItems{
    padding-top: 15px;
    display: flex;
    justify-content: space-around;
}

.customToolTip{
    text-align: justify;
    line-height: 1;
    padding: 5px;
    width: 210px;
    height: 100%;
    font-size: 12px;
    z-index: 90;
}

.analytacItem{
    margin: 10px 0;
}

.analytacText{
    text-align: center;
    line-height: 80%;
    font-size: 12px;
    padding: 0;
    margin: 0;
    color: rgb(92, 92, 92);
}

.analytacBuilding{
    text-align: center;
    font-size: 20px;
}

.rankingWrapper{
    cursor: pointer;
    padding: 2px 0;
}

.rankingWrapper:hover{
    background-color: rgb(245, 245, 245);
}

.rankingItem{
    font-size: 13px;
    display: flex;
    justify-content: space-between;
}

.line{
    height: 2px;
}

.rankingHeader{
    display: flex;
}

.rankingHeaderIcon{
    margin-right: 5px;
    font-size: 18px;
    color: #EE5E39;
}

.rankingHeaderTitle label{
    font-size: 12px;
    padding: 0;
    margin: 0;
    color: rgb(92, 92, 92);
}

.songonLabel i{
    font-size: 11px;
    margin-right: 0px;
}

.notFound {
    margin: 35px 0;
    font-size: 16px;
    text-align: center;
}

.songonLabel{
    width: 100%;
    background: linear-gradient(125deg,#00b4d8,#0096c7);
    border-radius: 0.25rem;
    color: #fff;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    font-family: 'Nunito', sans-serif;
}

@media (max-width: 768px) {
    .statistacsWrapper
    {
        height: 100%;
    }
}
