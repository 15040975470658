.item {
    background-color: #F5F6FA;
    padding: 20px 10px;
    margin-top: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.container {
    padding: 10px 25px;
}

.desc {
    color: #0b9aa7;
}

.success {
    color: #00b050
}

.pending {
    color: #ffc000
}

.danger {
    color: #FF3E3E
}

.accordItem {
    background-color: #F5F6FA !important;
    border: none;
}

.accordHeader button{
    background-color: unset;
    padding: 0;
}

.fileIcon{
    color: #144273;
    font-size: 18px;
}

.sanamj{
    margin-top: 15px;
    font-size: 13px;
}

.container-pdf {
    width: 100%;
    height: calc(65vh);
}

.sidebarBodyClass{
    height: calc(calc(100vh - calc(100vh - 100%)) - 100px);
}

.headerBottom{
    margin-top: 40px
}

.partnerchoose{
    margin-top: 20px;
}

.tableOuter{
    min-height: unset !important;
}

@media (max-width: 768px) {
    .item{
        margin-top: 10px;
    }
}
