.listContainer {
    margin-top: 100px;
    margin-left: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 3;
}

.searchContainer {
    margin-bottom: 30px;
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: whitesmoke;
    color: #0191c8;
    font-weight: bold;
    width: 100px;
    min-height: 100px;
    cursor: pointer;
    border: 2px solid #0191c8;
}

.filterContainer {
    display: flex;
    flex-direction: row;
}

.filterBar {
    background-color: white;
    border: 2px solid #0191c8;
    padding: 5px;
    width: 500px;
    max-height: 80vh;
    overflow-y: auto;
}

.listItem.active, .listItem:hover {
    background-color: #0191c8;
    color: white;
}

.searchInput {
    height: 40px;
    border: 4px solid #0191c8;
    width: 350px;
    padding-left: 10px;
}

.filterBtn {
    background-color: #0191c8;
    display: flex;
    border: unset;
    border: 2px solid #0191c8;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.filterBtn:hover {
    background-color: white;
    color: #0191c8;
    border: 2px solid #0191c8;
    transition: .3s;
}
.filterBtn i {
    margin-right: 2px;
}

ul.sanamj {
    margin-top: 10px;
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
}

ul.sanamj .sanamjList {
    color: #0191c8;
}

.choiceContainer {
    margin-top: 20px;
}
.choices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.choicesItem {
    padding: 5px 0px 5px 0px;
    border: 1px solid gray;
    cursor: pointer;
    width: 100%;
    text-align: center;
    background-color: #ccc;
    vertical-align: middle;
    align-items: center;
    display: flex;
    justify-content: center;
}
.choicesItem.active, .choicesItem:hover {
    border-color: #0191c8;
    background-color: #0191c8;
    color: white;
}

.chooseItemContainer{
    border: 1px solid #005B9A !important;
    background-color: #ffffff !important;
    display: flex;
    border-radius: 5px;
    width: fit-content;
}
.choosedItem{
    background-color: #005B9A !important;
    color: #fff;
}

.chooseItem:last-child{
    border-right: unset;
}

.chooseItem{
    padding: 7px 10px;
    cursor: pointer;
    border-right: 1px solid #005B9A;
}

.title {
    font-weight: bold;
    font-size: 14px;
    color: #0191c8;
}

.filterShuultuur {
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
}

@media (max-width: 500px) {
    .listContainer {
        width: 100%;
        margin-top: 66px;
        margin-left: 0px;
        padding-left: 5px;
        padding-right: 5px;
        z-index: 3;
    }

    .searchContainer {
        margin-bottom: 10px;
    }

    .filterContainer {
        flex-direction: column;
    }

    .menus {
        display: flex;
        flex-direction: row;
    }

    .filterBar {
        width: 100%;
        max-height: 70vh;
    }

    .choicesItem {
        font-size: 10px;
    }

    .listItem {
        font-size: 10px;
        min-height: 80px;
        border: 1px solid #0191c8;
    }

}

.suggesContainer {
    border: 2px solid #0191c8;
    top: 45px;
    left: 50px;
    width: 350px;
    position: absolute;
    background-color: #fff;
    z-index: 2;
}
.suggesItem {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}
.suggesItem:hover {
    background-color: #ccc;
    transition: 0.1s;
}
