.container{
    margin-top: 90px;
    padding: 0 20px;
}

.number{
	/* text-align: center; */
	font-size: 30px;
    color: #144273;
	/* justify-content: space-between; */
}

.number div{
	text-align: center;
	height: 80px;
}

.zuraas{
	border-left: 1px solid #ddd;
}

.boxHeader{
	height: 50px;
	display: flex;
	align-items: center;
}

.chart {
	width: 100%;
	height: 600px;
}

.rotate{
	transform: rotate(90deg)
}

/* ---------------------------------------- count down css ---------------------------------------------------------- */
.countContainer{
    width: 100%;
    text-align: center;
}

#clockdiv{
	display: inline-block;
	font-weight: 100;
	text-align: center;
	font-size: 25px;
}

#clockdiv > div{
	padding: 10px;
	border-radius: 3px;
	display: inline-block;
}

#clockdiv div > span{
	padding: 15px;
	width: 115px;
	border-radius: 3px;
	background: #F5F6FA;
	display: inline-block;
}

.smalltext {
    padding-top: 5px;
    font-size: 16px;
}

.mobileLand{
	height: 80%;
	width: 100%
}

.icon i{
	color: #fff;
	background-color: #005b9a;
	height: 26px;
	width: 26px;
	line-height: 26px;
	text-align: center;
	border-radius: 50%;
}

.fieldName{
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 10px 0;
	border-right: 1px dashed #ddd;
}

.valueName{
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 10px 0;
	padding-left: 10px;
}

.columnOne{
    display: flex;
    width: 30%;
	justify-content: flex-end;
}

.columnTwo{
    display: flex;
    width: 70%;
}

.iconExpress{
	background-color: #00afb9;
	width: 30px !important;
	height: 30px !important;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
}

.total_winner{
	margin-left: 5px;
}

.winnerContainer{
	width: 100%;
}

.winnerItem{
	cursor: pointer;
	border: 1px solid rgb(211, 211, 211);
	padding: 5px 8px;
	border-radius: 5px;
	user-select: none;
}

.winnersList{
	padding: 0 15px;
	max-height: 0px;
	overflow: hidden;
	transition: 0.3s;
	border: 1px solid rgba(128, 128, 128, 0.295);
	margin-bottom: 5px;
	border-top: none;
}

.hideBottomBorder{
	border-bottom: none !important;
}

.rotateIcon {
	transform: rotate(60)
}

.iconExpress2{
	background-color: #00afb9;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	padding: 0 5px;
}

.iconExpress i{
	font-size: 14px;
	color: #fff;
}

.moreDot{
	position: absolute;
	top: -10px;
	right: 5px;
	cursor: pointer;
	font-size: 14px;
	padding: 0 0 10px 10px;
	width: auto;
	color: #333;
}

.numberTitle{
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 10px;
	text-overflow: wrap;
	height: 38px;
	text-align: center;
}
.title1::after {
	content: "Байрны квот:";
}
.title2::after {
	content: "Бүртгүүлсэн:";
}

.headTitle{
	background-color: #005b9a;
	padding: 4px 0;
}

.headTitle label{
	width: 25%;
	text-align: center;
	font-size: 12.5px;
	color: #fff;
	font-weight: 500;
}

.bodyInfo label{
	width: 25%;
	text-align: center;
	margin-top: 3px;
	margin-bottom: 3px;
}

.description{
	background-color: #f5f6fa;
	border-radius: 8px;
	padding: 20px 10px;
	width: 100%;
	height: auto;
	color: #484848;
	font-size: 12.5px;
}

.headTop{
	background-color: #005b9a;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 4px 0;
	font-style: 14px !important;
}

@media (max-width: 768px) {
	.chart{
		height: 100vw;
		width: 85vh;
	}
	.mobileLand{
		height: 70vh;
		width: 100vw
	}
    #clockdiv div > span{
        width: 100px;
    }
	.boxHeader{
		height: 45px;
	}

	.columnOne{
        width: 50%;
    }

    .columnTwo{
        width: 50%;
    }

    .valueName img{
        width: 100%;
    }
}

@media (max-width: 992px) {
    .boxHeader{
        height: 60px;
    }
	.oneLine{
		display: static !important;
		flex-direction: column !important;
	}
	.numberTitle{
		font-size: 11px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.title1::after {
		content: "Байрны квот:";
	}
	.title2::after {
		content: "Бүртгүүлсэн:";
	}
	.headTitle label{
		font-size: 12px;
	}
	.bodyInfo label{
		font-size: 13px;
	}
}

.noHeight {
    min-height: unset!important;
}

.cardResidence {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	height: 40px;
	border: 1px solid rgb(30,91,154);
	transition: 0.2s;
	cursor: pointer;
}

.cardResidence.active {
	color: #ddd;
	background-color: rgb(30,91,154);
	transition: 0.2s;
}
.cardResidence.disabled {
	color: #333;
	background-color: #dddddd;
	transition: 0.2s;
	cursor: unset;
}

.animation {
	transition: 1s;
}

.oneWinner {
	border-bottom: 1px solid rgba(128, 128, 128, 0.24);
	padding: 4px 0;
}

.oneWinner:last-child {
	border: none;
}

.totalWinner{
	font-size: 15px;
	font-weight: 500;
	margin-left: 10px;
}
