.error-box {
    height: 100vh;
}

.error-center {
    display: flex;
    align-items: center;
    justify-content: center!important;
}

.error-main-text {
    font-size: 50px;
    font-weight: 600;
}
