.table-scroll {
    overflow-y: auto;
}

.table-scroll {
    border-collapse: separate;
}

.table-scroll thead{
    position: sticky;
    top: 0;
    vertical-align: middle;
    height: 40px;
    font-weight: 400;
    z-index: 1;
    top: -2px;
}
.table-scroll .table thead{
    background-color: #005b9a;
}

.table thead th {
    position: relative;
}

.table-scroll tbody td{
    color: black;
}

.table-scroll th,
.table-scroll td {
    color: #fff;
    text-align: center;
}

.table-scroll .table thead {
    color: #fff;
}

.table-scroll {
    min-height: 200px !important;
}

thead, tr, th {
    border: unset;
}

tbody, td, tfoot, th, thead{
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
}

.table>:not(caption)>*>* {
    border-bottom-width: 0.5px;
}

.emptycenter {
    text-align: center;
    vertical-align: middle;
}

.table-style::-webkit-scrollbar-track {
    margin-top: 40px;
	border-radius: 10px;
}

.table-style::-webkit-scrollbar {
	width: 6px;
    height: 6px;
    background-color: #181a20;;
}

.table-style::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #5e6673;
}

.filterIcon{
    float: right;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    cursor: pointer;
    transition: 0.3s;
    color: rgb(204, 204, 204);
}

.filterIcon:hover{
    color: #fff
}

.filterOptions{
    position: absolute;
    right: 0;
}

.popoverContainer{
    background-color: #fff;
    color: #181a20;
    font-weight: 400;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.55);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.55);
}

.headerDiv{
    margin-right: 10px;
}
