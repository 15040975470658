.pageHeader{
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    padding-left: 10px;
    position: relative;
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
    color: #144273;
}

.vLine{
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 20px;
    width: 3px;
    background: #0ec6d5;
}

.itemContainer{
    background-color: #fff;
    box-shadow: 0 3px 8px 0 #d9e2ec;
    padding: 20px;
    margin-top: 15px;
    cursor: pointer;
    transition: 0.3s;
}

.itemContainer:hover{
    background-color:  rgba(243, 243, 243, 0.705);
}

.itemContainer:first-child{
    margin-top: 0;
}

.theNumber{
    background: #0191C8;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
    border-radius: 0.25rem;
}

.listTitle{
    color: #405189;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 17px;
}

.smallText{
    color: #0b9aa7;
    font-size: 13px;
    margin-right: 10px;
    margin-top: 5px;
}

.itemDesc{
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 10px;
}

.legalTabs{
    background-color: #fff;
    padding: 25px 15px;
    box-shadow: 0 3px 8px 0 #d9e2ec;
    border-radius: 0px;
    margin-bottom: 15px;
}

.navLinkitem:hover{
    background-color: rgba(243, 243, 243, 0.705);
}

.navLinkitem{
    border: unset !important;
    transition: 0.3s !important;
    margin-top: 5px !important;
}

.navLinkActive{
    transition: 0.3s !important;
    background-color: rgb(241, 241, 241) !important;
    border: 1px solid #0b9aa7 !important;
}


.emptyList{
    text-align: center;
    margin-top: 10vh;
    font-size: 20px;
}
