.height {
    max-height: 500px;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .height {
        max-height: 300px;
        overflow-y: auto;
    }
}

.itemWrapper{
    margin-top: 15px;
    background-color: rgba(240, 240, 240, 0.774);
    border-radius: 10px;
    padding: 10px;
}

.iconscontainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.iconItem{
    margin-top: 10px;
    margin-right: 10px;
    width: 230px;
    display: flex;
    align-items: center;
}

.iconItem i {
    font-size: 15px;
    color: #fff;
    background-color: #005B9A;
    border-radius: 50%;
    padding: 5px;
    margin-right: 5px;
}
