.listItem{
    padding: 10px 10px !important;
    width: 100%;
    cursor: pointer;
    position: relative;
    border: 1px solid rgb(0,91,154);
    border-radius: 5px;
    height: 70px;
}

.title {
    font-size: 11px!important;
}

.listItem i{
    color: #808080;
    margin-right: 6px;
    font-size: 16px
}

.nameIcon{
    font-size: 13px;
    width: 80%;

    text-align: start;
    vertical-align: middle;
    align-items: center;
    display: flex;

    height: 38px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.countNumber{
    color: #808080;
    font-size: 11px;
    display: flex;
    height: 38px;
    align-items: end;
}

.items{
    background-color: #00afb9;
    border-radius: 4px;
    padding: 5px 10px;
    width: 100%;
    color: #fff;
    font-size: 11px;
    border: 1px solid #f0f0f0;
    cursor: pointer;
}

@media (max-width: 992px){
    .listContainer{
        width: auto;
    }
    .nameIcon{
        font-size: 10px;
    }
    .listItem i{
        font-size: 13px
    }
    .listItem {
        padding-left: 10px;
    }
    .countNumber{
        font-size: 9px;
    }
    .items{
        font-size: 10px;
    }
    .resultTitle{
        font-size: 10px !important;
    }
}

.activeselect {
    border-bottom: 5px solid rgb(0,91,154);
    border-radius: 6px;
    transition: .2s;
}

.cardItem {
    height: 73px;
}

.sliderContainer {
    padding: 0 10px
}
