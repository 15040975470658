.containerPages {
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerPerPage {
    display: flex;
    justify-content: center;
}
.containerPerPage .btn-primary {
    color: #fff;
    background-color: #005b9a;
    border: none
}

.containerPagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerPagination .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #005b9a;
    border-color: #005b9a;
}

.containerJumpPage {
    display: flex;
    justify-content: center;
}
