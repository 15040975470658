.oneLegend{
    text-align: justify;
    margin: 5px 0;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
}

.legendMark{
    position: absolute;
    left: 0px;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    height: 11px;
    top: 50%;
    width: 11px;
    background-color: red;
}

.agendaTitles{
    text-align: center;
    font-size: 16px;
    background-color: #F1F1F1;
}

.anayltacCards{
    background-color: rgb(233, 233, 233);
    height: 150px;
}

.subAgendaTitles{
    font-size: 16px;
    text-align: center;
    background-color: #F1F1F1;
}

.markTitles{
    position: relative;
}

.agendaLegends{
    margin: auto;
    font-size: 13px;
}

.infoCard{
    display: flex;
    background-color: rgba(226, 226, 226, 0.5);
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.infoCardText{
    color: rgb(109, 109, 109);
    font-size: 13px;
}

.infoCardIcon{
    display: flex;
    justify-content: center;
    align-content: center;
}

.infoCardIcon i {
    font-size: 35px;
    margin: auto;
    padding: 20px;
    /* width: 80px;
    height: 80px; */
    display: flex;
    justify-content: center;
    align-content: center;
    color: #005B9A;
}

.infoCardNumber{
    font-size: 16px;
    color: rgb(31, 31, 31) !important;
}

/* .activeSubLegend {
    background-color: rgb(138, 66, 66);
    padding: 10px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
} */

.activeSubLegend {
    margin-left: -9px;
    padding: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-right: 0;
    border-right: none !important;
    display: inline-block;
    position: relative;
    width: 100%;
}
.activeSubLegendbefore,
.activeSubLegendafter {
    content: '';
    position: absolute;
    width: 30px;
    height: 40px;
}
.activeSubLegendbefore {
    border-bottom-right-radius: 50%;
    right: -20px;
    top: 0;
    transform: translateY(-100%);
}
.activeSubLegendafter {
    border-top-right-radius: 50%;
    right: -20px;
    bottom: 0;
    transform: translateY(100%);
}

.subagendaCard{
    border-radius: 10px;
    padding: 20px 30px;
}

.subagendaCardttiel{
    font-size: 15px;
    font-weight: 500;
}

.subagendaCardPercent{
    font-size: 20px;
    margin: 10px 0;
    margin-bottom: 30px;
}

.subagendaDetail{
    margin: 15px 0;
}

.numberWithPercent{
    text-align: right;
}

.bigPercent{
    display: flex;
    align-items: center;
    font-size: 16px;
    /* background-color: rgb(202, 202, 202); */
    border: 1px solid rgb(185, 185, 185);
    padding: 3px 5px;
    border-radius: 5px;
}
.numberWithPercent .number{
    font-size: 16px;
}

.numberWithPercent .percent {
    font-size: 11px;
    color: #005B9A
}

@media (max-width: 768px) {
    .oneLegend{
        margin-left: 5px;
    }
}
