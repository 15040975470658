.row{
    margin-top: 20px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.middleLine{
    position: absolute;
    top: 15px;
    width: 100%;
}

.step{
    z-index: 2;
    width: 60px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(196, 196, 196);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    transition: 0.3s;
}

.activeIcon {
    border: 1px solid #00afb9;
    transition: 0.3s;
    color: #00afb9;
    background-color: rgb(233, 233, 233);
}

.activedIcon{
    transition: 0.3s;
    background-color: #00afb9;
}

.step div:last-child{
    /* width: 50px; */
    display: flex;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
}

.content{
    margin-top: 10px;
}
