.mainContent{
    margin-top: 76px;
    margin-left: 260px;
    transition: 0.2s;
    padding: 20px;
}

.chooseItem{
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 25vh;
    text-align: center;
    color: #333333
}

.chooseItem div i {
    font-size: 45px;
    color: #005b9a ;
    text-align: center;
}

@media (max-width: 800px)  {
    .mainContent{
        margin-left: 0;
        padding: 10px;
        margin-top: 80px;
    }
}
