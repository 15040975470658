.react-datepicker{
    font-family: "Rubik", sans-serif;
    border: none;
    box-shadow: 0 3px 6px 0 rgb(29 53 87 / 30%);
    background-color: #fff !important;
    color: white !important
}

.react-datepicker__month{
    background-color: #fff;
}

/* Header style */
.react-datepicker__header{
    background-color: rgba(0,0,0,.03);
    /* background-color: #f5f6fa; */
    color: #080808 !important;
    border: none;
}
.react-datepicker__header div{
    color:  #080808 !important;
    font-weight: 400;
}

/* Body style өдөрүүдийн style */
.react-datepicker__month div{
    color: #323232;
}

/*  Өдөр сонгох hover */
.react-datepicker__day:hover{
    transition: 0.2s;
    background-color: #0ec6d5 !important;
    color: white !important;
}

/* Сонгогдсон range style */
.react-datepicker__day--in-range{
    transition: 0.2s;
    background-color: #0ec6d5 !important;
    color: #144273 !important;
    border: none;
}
/* range сонголт хийж байх үед */
.react-datepicker__day--in-selecting-range{
    transition: 0.2s;
    background-color: rgb(14, 198, 213, 0.6) !important;
    color: #fff
}

/*  Өнөөдөр харуулах style */
.react-datepicker__day--today{
    border: 1px solid #0ec6d5;
    border-radius: 5px;
    background-color: unset;
}

.clearlyButton{
    background-color: rgba(255,192,0,0);
    border: none;
    border-radius: 50%;
}

.react-datepicker__day--keyboard-selected{
    transition: 0.2s;
    background-color: unset ;
}

/* Арилгах, цэвэрлэх товч style */
/* .react-datepicker__close-icon{

} */

.form-control[disabled] ~ .react-datepicker__close-icon {
    display: none;
}

.react-datepicker__close-icon::after{
    cursor: default;
    background-color: rgba(255,192,0,0);
    color: #CCCCCC;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 2px;
    margin-right: 0px;
    font-size: 22px;
    line-height: 1;
    text-align: center;
    display: flex;
    align-items: center;
    content: "×";
}
.validateErrorBorder~.react-datepicker__close-icon::after{
    cursor: default;
    margin-right: 15px;
}

/* Popper гарж ирсэн ий дараа input рүү зааж байгаа сум */
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    border-bottom-color: #2B3139 !important;
}

/* Disabled болсон өдрийн style */
.react-datepicker__day--disabled{
    cursor: default;
    color: #ccc !important;
}

.react-datepicker__day--disabled:hover{
    background-color: unset !important;
    color: #ccc !important;
}

.verticalDateLine:after {
    content: '';
    width: 0;
    height: 60%;
    position: absolute;
    border-left: 1px solid #ccc;
    top: 50%;
    transform: translateY(-50%);
    right: 35px;
}

.clearlyButton[disabled] {
    visibility: hidden
}

.react-datepicker-popper{
    z-index: 1500 !important;
}

.react-datepicker-wrapper{
    width: 100%;
}

/* Нэг өдөр сонгодог datepicker-ийн сонгогдсон өдрийн style */
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #0ec6d5;
    color: #144273 !important;
}

.datepickerInput{
    display: block !important;
    padding: 6.5px;
    padding-left: 7px;
    font-size: 14px !important;
}

.datepickerInput[disabled] {
    background-color: #e9ecef;
    opacity: 1;
}

.react-datepicker__time-container{
    color: #080808;
}

.react-datepicker__year-text {
    color: #080808;
}

.react-datepicker__year-text--disabled {
    color: #9d9d9d;
}

.react-datepicker__close-icon {
    display: none;
}
