.Badge{
    position: absolute;
    right: -3px;
    top: -8px!important;
    font-size: 10px;
    border-radius: 50rem!important;
    background-color: #ff3e3e;
    color: #fff;
    display: inline-block;
    padding: 0.35em 0.65em;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    font-family: 'Nunito', sans-serif;
}

.desc{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.5;
    padding-bottom: 0.5rem;
    padding-top: 0.375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #144273;
    border-bottom: 1px solid #ccc;
}

.notificationItem{
    transition: none 0s ease 0s;
    border-radius: 0.25rem;
    white-space: normal;
    padding: 8px 6px;
    margin: 0px 5px;
    padding-left: 25px;
    width: 100%;
    text-decoration: none;
    color: rgb(27, 46, 75);
    font-size: 13px;
    display: flex;
    align-items: center;
    position: relative;
}

.dot{
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    z-index: 5;
}

.buttons{
    margin: 10px 0 5px 0;
    display: flex;
    gap: 10px;
    margin-left: 15px;
    transition: 0.2s;
}

.buttons div{
    border-radius: 20px;
    padding: 0 5px;
    font-size: 12px;
    transition: 0.2s;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
}

.buttons div:hover{
    transition: 0.2s;
    background-color: rgba(235, 235, 235, 0.568);
}

.buttons div.active{
    transition: 0.2s;
    background-color: #144273;
    color: #fff;
}

.gotoTable{
    border-radius: 20px;
    margin-right: 5px;
    font-size: 13px;
    transition: 0.2s;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    color: #144273;
}

.notificationItem:first-child{
    margin-top: 3px
}

.notificationItem:hover{
    background-color: #f5f6fa;
}

.trashButton{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.notificationTitle{
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.notificationTime{
    color: rgb(131, 146, 165);
    font-size: 11px !important;
    display: block;
    margin-top: 4px
}

.status{
    background-color: #00afb9;
    font-size: 11px;
    border-radius: 15px;
    padding: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.seeAll{
    text-align: center;
    font-size: 12.5px;
    color: #333;
    margin: 10px 10px;
    cursor: pointer;
    text-decoration: underline;
}

.trash{
    width: 20px !important;
    height: 20px !important;
    font-size: 10xp !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    background-color: #ff3e3ed3;
    border-radius: 50%;
    transition: 0.2s ease-out;
}

.trash:hover{
    background-color: #ffffff;
    border: 1px solid #FF3E3E;
    color: #FF3E3E !important;
    border-radius: 50%;
    z-index: 999 !important;
}

.disabled{
    color: grey;
    cursor: wait;
}

.notifList{
    overflow-y: auto;
    max-height: calc(60vh);
}

.agreedButtons{
    padding: 10px 0;
    display: flex;
    gap: 15px;
    justify-content: center;
}

.answerOutRejected{
    text-align: center;
}
.answerOutAccepted{
    text-align: center;
}
