.hideImage {
    width: 0px;
    height: 0px;
}

.imageTitle{
    bottom: 0;
    left: 0;
    position: absolute;
    padding: 2px 10px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.426);
}
