.colCustom{
    margin-top: 14vh;
    margin-bottom: 30px;
}

.colCustom h2{
    text-align: center;
    font-family: "Nunito", sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: #005b9a;
}

.col{
    display: flex;
    justify-content: end !important;
}

.col:last-child{
    justify-content: start !important;
}

.verticalLine{
    height: 80%;
    width: 1px;
    background-color: rgba(131, 131, 131, 0.644);
}

.hutulburBtn{
    margin-right: 20px;
    font-size: 35px;
    height: 15%;
    width: 40%;
}

.songonBtn{
    margin-left: 20px;
    font-size: 35px;
    height: 15%;
    width: 40%;
}

h3 {
    color: #262626;
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 4px;
    text-align: center;
}
p {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #666;
}
p.small {
    font-size: 14px;
}
.go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #0191c8;
    border-radius: 0 4px 0 32px;
}
.go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
}
.card1 {
    display: block;
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    padding: 47px 24px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    box-shadow: 0 3px 8px 0 #d9e2ec;
    width: 510px;
    cursor: pointer;
}
.card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #0191c8;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.5s ease-out;
}
.card1:hover:before {
    transform: scale(52);
}
.card1:hover p {
    transition: all 0.3s ease-out;
    color: #fff;
}
.card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}

.card1 i{
    color: #0191c8;
    font-size: 40px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px
}

.card1:hover i{
    color: #fff !important;
}

.card1 p{
    font-size: 13px;
    color: #666;
}

.card1 hr{
    margin: 0;
    background-color: #484848;
}

.card1:hover hr{
    background-color: #fff;
}

.readMoreBtn{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px){
    .col{
        margin-top: 20px;
        justify-content: center !important;
    }
    .colCustom{
        margin-top: 13vh;
        margin-bottom: 10px;
    }
    .colCustom h2{
        font-size: 25px;
    }
}
