.inputSize{
    width: 200px;
    margin-right: 10px;
    margin-left: 5px;
}

.buttonSize{
    width: 120px;
}

.ableInfo{
    font-size: 16px;
    color: rgb(56, 56, 56);
}

@media (max-width: 1201px) {
    .inputSize{
        width: 150px;

    }
    .buttonSize{
        width: 90px;
    }
}

@media (max-width: 993px) {
    .inputContainer{
        display: block;
    }

    .inputContainer label{
        margin-left: 5px;
        margin-top: 10px;
    }

    .inputSize{
        width: 100%;
        margin-left: 0;

    }
    .buttonSize{
        margin-top: 10px;
        width: 90px;
    }
}
