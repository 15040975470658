.active{
    background-color: #0dad13;
    border-radius: 0.25rem;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
}

.inactive{
    background-color: #ff3e3e;
    border-radius: 0.25rem;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
}

.imgContainer{
    width: 100%;
    height: 250px;
}

.imgContainer img{
    height: 100%;
    width: 100%;
}

@media (max-width: 768px) {
    .imgContainer{
        height: 180px;
    }
}
