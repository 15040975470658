.specialNews{
    padding: 20px;
    box-shadow: 0 3px 8px 0 #d9e2ec;
    border-radius: 0px;
    background-color: #fff;
    margin-bottom: 20px;
}

.smallImage{
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
}

.imageContainerx{
    display: flex;
    justify-content: center;
    max-width: 110px;
    max-height: 110px;
}

.imageDesc{
    display: flex;
    font-size: 14px;
}

.imageDesc div{
    margin-top: 10px;
    margin-left: 10px;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #405189;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

.newsSmallText{
    color: #0b9aa7;
    font-size: 12px !important;
    margin-right: 10px;
    margin-top: 5px;
}

.pageHeader{
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    padding-left: 10px;
    position: relative;
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
    color: #144273;
}

.vLine{
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 20px;
    width: 3px;
    background: #0ec6d5;
}


.oneNews{
    padding: 20px 20px 30px 20px;
    box-shadow: 0 3px 8px 0 #d9e2ec;
    border-radius: 0px;
    background-color: #fff;
    position: relative;
    margin-bottom: 20px;
}

.newsTitle{
    color: #405189;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 17.5px;
    cursor: pointer;
}

.titlesImageContainer{
    display: flex;
    justify-content: center;
    max-height: 600px;
}

.oneImage{
    max-height: 100%;
    max-width: 100%;
}

.longDesc{
    margin-top: 10px;
    text-align: justify;
}

.longDesc img{
    max-width: 100%;
}

.numberContainer{
    display: flex;
    position: absolute;
    bottom: 5px;
}

.longDesc p {
    color: rgb(49, 49, 49);
    font-size: 15px;
}

.reporterContainer{
    margin-top: 15px;
    padding: 20px;
    box-shadow: 0 3px 8px 0 #d9e2ec;
    border-radius: 0px;
    background-color: #fff;
}

.activeList{
    background-color: rgba(182, 182, 182, 0.5);
    padding: 3px;
    border-radius: 3px;
}
