.countContainer{
    margin: 0 auto;
}

.countTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #144273;
    font-size: 13px
}

.countNumber{
    font-size: 30px;
    font-family: Arial, Arial, Helvetica, sans-serif;
    font-weight: 400;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #144273;
}

.numberSlider{
    margin-bottom: 20px;
}

.slider{
    display: block;
    margin: 0 !important;
}

.sliderLabel{
    font-size: 13px;
    color: rgb(84, 84, 84);
}

.iconExpress{
	background-color: #00afb9;
	width: 30px !important;
	height: 30px !important;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
    color: #fff !important;
    font-size: 14px;
}
