small {
    color: #000
}

.datatable-footer {
    margin-top: 1rem;
}

.seachContainer input{
    border: none;
    background-color: #fff;
    width: 230px !important;
    border: 1px solid rgb(220, 222, 226);
    border-radius: .2rem;
    padding: 6px;
}

.seachContainer input::placeholder {
    font-size: 13px;
}

@media (max-width: 992px) {
    .seachContainer input{
        width: 100% !important;
    }
}
