:root {
    --toastify-color-success: #0dad13;
    --toastify-color-warning: #fabc01;
    --toastify-color-error: #e93a3a;
}

.Toastify__close-button {
    color: #fff;
}

.Toastify__toast{
    box-shadow: rgb(73 129 163 / 27%) 0px 0px 14px -3px;
    border-radius: 0.25rem;
    font-family: "Rubik", sans-serif;
}
