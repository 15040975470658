.newsTabs{
    border-bottom: none;
}

.newsTabs .nav-link{
    border-bottom: 1px solid #005b9a;
    border-radius: 0;
    padding: 0;
    height: 35px;
    color: #333 !important;
}

.newsTabs .active{
    border-radius: 0;
    border-top: 1px solid #005b9a !important;
    border-right: 1px solid #005b9a !important;
    border-left: 1px solid #005b9a !important;
    border-bottom: none !important;
    color: #144273 !important;
    font-weight: 500;
}

.tx-blue{
    color: #144273;
}
