
.dropdownMenu a{
    justify-content: unset !important;
}

.topHeader{
    background-color: #005b9a;
    height: 36px;
    z-index: 999 !important;
}

.logoDesktopNone{
    display: none !important;
}

@media (max-width: 992px) {
    .topHeader{
        display: none !important;
    }

    .logoDesktopNone{
        display: block !important;
    }
}
