.video-overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.80);
    opacity: 0;
    transition: all ease 500ms;
}

.video-overlay.open {
    position: fixed;
    z-index: 1000000;
    opacity: 1;
}

.video-overlay-close {
    position: absolute;
    z-index: 1000;
    top: 15px;
    right: 20px;
    font-size: 36px;
    line-height: 1;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: all 200ms;
}

.video-overlay-close:hover {
    color: #ffc000;
}

.video-overlay iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    /* width: 90%; */
    /* height: auto; */
    box-shadow: 0 0 15px rgba(0,0,0,0.75);
}

@media only screen and (max-width: 768px) {
    .video-overlay iframe{
        width: 100%;
    }
}
