.cborder {
    width: 33px;
    height: 35px;
    line-height: 35px;
    padding: 0px;
}
.map-controls-zoom .cborder {
    height: auto!important;
}
.cborder.btn.btn-primary:hover {
    background-color: #0ec6d5!important;
}

/* fullscreen style  */
.cborder .ol-unselectable.ol-control {
    position: relative!important;
    background-color: none!important;
    padding: 0!important;
    border-radius: none!important;
    width: 100% !important;
    height: 100% !important;
}
.cborder .ol-zoom {
    top: unset !important;
    left: unset !important;
}
.cborder .ol-control button {
    position: relative;
    margin: 0px!important;
    width: 100%!important;
    background-color: rgba(0,0,0,0.4) !important;
    height: 100%!important;
    line-height: 2 !important;
}
.cborder .ol-control button:hover,.cborder .ol-control button:focus {
    background-color: #0ec6d5!important;
}
.cborder .ol-zoom button{
    height: 35px !important;
}

/* mashtab ийн загвар */
.mashtab .ol-scale-line {
    position: unset;
    bottom: unset;
    left: unset;
    background: unset;
    border-radius: unset;
    padding: 2px;
}
.mashtab .ol-scale-line .ol-scale-line-inner {
    border: 1px solid #eee;
    border-top: none;
    border-left: none;
    border-right: none;
    color: #eee;
    font-size: 10px;
    text-align: center;
    margin: 1px;
}

.ol-popup .ol-c-times {
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s;
}
.ol-popup .ol-c-times:hover {
    transition: 0.3s;
    background-color: #eee;
    cursor: pointer;
}

.popup-mobile-container{
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 15px;
    padding-top: 30px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    z-index: 1043 !important;
    overflow: hidden;
}

header ~ div .popup-mobile-container{
    top: 62px !important;
}

.popup-close-button{
    border-radius: 50%;
    cursor: pointer;
    padding: 5px 10px;
    transition: .3s;
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: 5px;
}

.popup-close-button:hover{
    background-color: #eee;
}


.cborder.disabled{
    background-color: grey;
}
