.fieldName{
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 10px 0;
}

.valueName{
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 10px 0;
}

.columnOne{
    display: flex;
    width: 33%;
}

.columnTwo{
    display: flex;
    width: 67%;
}

@media (max-width: 768px){
    .columnOne{
        width: 50%;
    }

    .columnTwo{
        width: 50%;
    }
}
