.baselayerSwitcher{
    position: absolute;
    box-shadow: rgb(73 129 163 / 27%) 0px 0px 14px;
    z-index: 40;
    transition: 0.3s;
    background-color: #fff;
    display: flex;
    bottom: -91px;
}

.layer{
    margin: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #ffffff;
    padding-right: 5px;
    width: 155px;
}

.layer input{
    -webkit-appearance: radio;
    margin-right: 5px;
}

.layer img{
    margin-right: 5px;
    height: 40px;
    width: 40px;
}

.active{
    border: 1px solid #00AFB9;
}
.closeButton{
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #00AFB9;
}
