.card{
    box-shadow: rgb(73 129 163 / 27%) 0px 0px 14px -3px;
    border: none;
}


.cardHeader {
    font-size: 12.5px;
    font-weight: 500;
    text-transform: uppercase;
    color: #144273;
    padding: 0.8rem 1rem;
    border-bottom: none;
    background-color: rgba(0,0,0,.03);
    position: relative;
    
}

.cardBody {
    padding: 1rem 1rem;
}

.verticalLine{
    height: 50%;
    width: 3px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #0ec6d5;
    background-size: 400% 400%;
}

.column{
    position: relative;
}

.bodyTitle{
    margin-bottom: 20px;
    color: #656565;
    font-size: 12px;
    text-align: center;
}

.line{
    width: 1px;
    height: 90%;
    background-color: #ccc;
    position: absolute;
    right: 0;
}

@media (max-width: 768px) {
    .cardHeader{
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        color: #144273;
        padding: 0.5rem 0.6rem;
        border-bottom: none;
        background-color: rgba(0,0,0,.03);
        position: relative
    }
    .line{
        display: none;
    }
}
