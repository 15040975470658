.maintitle {
    text-transform: uppercase;
    padding-left: 10px;
    position: relative;
    display: inline-block;
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 15px;
    font-weight: 500;
    margin-top: 10px;
}
.maintitle:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 20px;
    width: 3px;
    background: #0ec6d5;
}
