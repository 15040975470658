.rc-slider-rail {
    background-color: rgb(187, 187, 187);
}

.rc-slider-track{
    background-color: #005B9A;
}

.rc-slider-handle{
    background-color: #005B9A;
    border: 1px solid #005B9A;
    opacity: 1;
}

.rc-slider-handle:hover{

}


.rc-slider-handle-dragging{
    background-color: #005B9A;
}
