.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000!important;
}

.reverseSpinner {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    color: #fff;
    animation: rotation 1s linear infinite;
}

.reverseSpinner:after, .reverseSpinner:before {
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: #005b9a;
    border-radius: 30%;
    animation: animloader38 1s infinite ease-in-out;
}

.reverseSpinner:before {
    background-color: #ff3e3e;
    transform: scale(0.5) translate(-48px, -48px);
}

.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000003!important;
}

.reverseSpinner.medium {
    width: 65px;
    height: 65px;
}

.absolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    text-align: center;
}

.background{
    background-color: rgba(245, 246, 250, 0.5);
    z-index: 1002;
}

.backgroundBlack {
    background-color: rgba(245, 246, 250, 1);
}

@keyframes animloader38 {
    50% {
        transform: scale(1) translate(-50%, -50%);
   }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
   }
    100% {
        transform: rotate(360deg);
   }
}
