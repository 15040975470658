.newsTabs{
    border-bottom: none;
}

.newsTabs .nav-link{
    border-bottom: 1px solid #005b9a;
    border-radius: 0;
    padding: 0;
    height: 35px;
}

.newsTabs .active{
    border-radius: 0;
    border-top: 1px solid #005b9a !important;
    border-right: 1px solid #005b9a !important;
    border-left: 1px solid #005b9a !important;
    border-bottom: none !important;
}

.tx-blue{
    color: #144273;
}

.tx-black{
    color: #333333
}
