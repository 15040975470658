.eskizImg{
    width: 60%;
    margin-bottom: 30px;
}

.container{
    padding: 0 30px;
}

.sidebarBodyClass{
    height: calc(calc(100vh - calc(100vh - 100%)) - 100px);
}

@media (max-width: 768px) {
    .eskizImg{
        width: 100%;
    }
    .container{
        font-size: 12px;
    }
}
