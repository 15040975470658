
.waves {
    position: absolute;
    width: 210px;
    height: 210px;
    background: rgba(255, 255, 255, 0.3);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-radius: 100%;
    right: -70px;
    bottom: -70px;
    z-index: -1;
    animation: waves 3.5s ease-in-out infinite;
}
.wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
@keyframes waves {
    0% {
      -webkit-transform: scale(0.2, 0.2);
      transform: scale(0.2, 0.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
      opacity: 0.9;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
      -webkit-transform: scale(0.9, 0.9);
      transform: scale(0.9, 0.9);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

.wrapper {
    display: inline-block;
    transform: translate(0)
}

.video-main {
    position: relative;
    display: inline-block;
}

.video-button {
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 100%;
    background: transparent;
    color: #333;
    display: inline-block;
    background: #fff;
    z-index: 999;
    font-size: 18px;
    cursor: pointer;
}
.video-button :hover {
    color: #0191c8;
}
