.offcanvas {
    z-index: 1050!important;
    visibility: visible;
}

.offCanvasBody {
    z-index: 1060!important;
}

.offcanvas-header {
    justify-content: center;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
}

.headerLogo div{
    justify-content: space-between;
}

.userIcon {
    font-size: 25px;
}

@media only screen and (max-width: 992px) {
    .main-header {
        padding: 10px 8px;
    }
    .headerLogo{
        width: 100%;
    }
    .verticalSlash{
        display: none;
    }
    .navbar-light .navbar-toggler {
        z-index: 1000 !important;
    }
}

.verticalSlash{
    color: #ddd
}

.nav-item{
    text-align: center;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #dedede!important;
}

.main-header a{
    text-transform: uppercase;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #222b3b !important;
}

.main-header .nav-link{
    height: 100%;
    align-items: center;
    display: flex;
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
    padding-right: 0;
    padding-left: 0;
    justify-content: center;
    font-family: "Nunito", sans-serif;
}

.main-header .dropdown-item{
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.dropdown-item{
    padding: 8px 5px !important;
}

.main-header .nav-link.active {
    border-bottom: 2px solid #005b9a;
    transition: 0.1s ease-out;
    color: #144273 !important;
    font-weight: 800;
}
