.legendContainer{
    position: absolute;
    right: 0;
    bottom: 60px;
    z-index: 500;
    max-height: 400px;
    width: 150px;
    background-color: #fff;
    box-shadow: rgb(73 129 163 / 27%) 0px 0px 14px;
    border: 1px solid #ccc;
    cursor: unset;
    font-size: 12.5px;
    overflow-y: auto;
    transition: 0.3s;
}

.legendContainer.hide {
    right: -150px;
}

.legendTitle{
    text-align: center;
    font-size: 13px;
    background-color: rgba(0,0,0,.03);
    font-weight: 500;
    margin: 7px 0;
    margin-left: 5px;
}

.closeBtn {
    transition: .3s;
    background-color: #00AFB9;
    width: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
}

.baselayerSwitcher{
    position: absolute;
    top: 60%;
    transform: translateY(-60%);
    box-shadow: rgb(73 129 163 / 27%) 0px 0px 14px;
    z-index: 40;
    transition: 0.3s;
    background-color: #fff;
    display: flex;
}

.layer{
    margin: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #ffffff;
    padding-right: 5px;
    width: 155px;
}

.layer input{
    -webkit-appearance: radio;
    margin-right: 5px;
}

.layer img{
    margin-right: 5px;
    height: 40px;
    width: 40px;
}

.active{
    border: 1px solid #00AFB9;
}

.closeButton{
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #00AFB9;
}

.legendWrapper{
    margin-left: 7px;

}
