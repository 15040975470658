.userprofile {
    height: 233px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.success{
    color: #00b050
}

.danger{
    color: #FF3E3E
}

.pending{
    color: #ffc000
}

.info{
    font-size: 15px;
}

@media (min-height: 300px) and (max-width: 768px) {
    .userprofile{
        height: 180px;
        width: 180px !important;
    }
}
