.sliderContainer{
    padding: 0 12px;
}

.statusGreen {
    top:10px;
    right: 10px;
    position: absolute;
    background-color: #0dad13;
    border-radius: 0.25rem;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
}

.statusRed{
    top:10px;
    right: 10px;
    position: absolute;
    background-color: #c80505;
    border-radius: 0.25rem;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
}

.subTitle{
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
}

.locationItemContainer{
    display: flex;
}

.locationItem{
    font-weight: 400;
    font-size: 14px;
}

.locationItem p {
    font-size: 14px;
}

.locationItem span{
    font-size: 15px;
    font-weight: 500;
}

.items {
    margin-top: 10px;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
}

.items div {
    list-style-type: none;
    display: inline-block;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(108 118 134 / 10%) 0px 0px 1.25rem;
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    flex: 1 1 20%;
}

.items div span{
    color: rgb(98, 96, 108);
    font-weight: 400 !important;
    font-size: 25px !important;
}

.items div p {
    margin-bottom: 5px;
    color: rgb(98, 96, 108);
}

.items div span {
    font-size: 16px;
    font-weight: 500;
}

.items div i {
    font-size: 25px;
    color: rgb(98, 96, 108);
    margin-bottom: 10px;
}

.apartSmallItem{
    text-align: center;
    margin-bottom: 20px;
}

.apartSmallItem span{
    font-size:  20px;
}

.apartSmallItem i{
    font-size: 25px;
    margin-left: 10px;
    color:rgb(98, 96, 108)
}

.specialitem {
    margin-bottom: 15px;
}

.specialitem .statusTrue {
    display: inline-block;
    background-color: rgb(229, 255, 248);
    color: rgb(29, 194, 149);
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 50px;
    text-align: center;
    font-size: 13px;
    transition: all 0.6s ease 0s;
}

.specialitem .statusFalse {
    display: inline-block;
    background-color: rgb(255, 229, 229);
    color: rgb(194, 29, 29);
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 50px;
    text-align: center;
    font-size: 13px;
    transition: all 0.6s ease 0s;
}

.specialitem span {
    font-size: 14px;
    color: rgb(98, 96, 108);
    padding-left: 10px;
}

.iconDugui {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    margin-right: 5px;
}

.iconWeb{
    color: #2F9F6A;
    border: 1px solid #2f9f6b81 ;
}

.iconTwitter{
    color: #1C9CEA;
    border: 1px solid #1c9bea81 ;
}

.iconInstagram{
    color: #AE12E0;
    border: 1px solid #ac12e086 ;
}

.iconPhone{
    color: #2CBB00;
    border: 1px solid #2cbb0065 ;
}

.iconMail{
    color: #CD4438;
    border: 1px solid #cd443875 ;
}

.iconFacebook{
    color: #0570E6;
    border: 1px solid #0572e669 ;
}

.iconWeb:hover{
    background-color: #2f9f6b36 !important;
}
.iconTwitter:hover{
    background-color: #1c9bea3a !important;
}
.iconInstagram:hover{
    background-color: #ac12e038 !important;
}
.iconPhone:hover{
    background-color: #2cbb0018 !important;
}
.iconFacebook:hover{
    background-color: #0572e62b !important;

}
.iconMail:hover{
    background-color: #cd443831 !important;
}

.planImage {
    height: 400px;
}

.row{
    margin-top: 20px;
}

.icons{
    display: flex;
    justify-content: center;
    padding: 0;
}

.iconContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: 0.3s;
}

.iconContainerActive{
    max-width: 500px;
    transition: 0.3s;
}

.iconContainerInactive{
    max-width: 50px;
    transition: 0.3s;
}

.icons div{
    margin-right: 2px;
}

.specialContainer{
    margin-top: 15px;
}

.roomSizes{
    margin-top: 15px;
}
.imageContainer{
    margin-top: 15px;
    margin-bottom: 15px
}

.miniCard{
    display: flex;
    width: 100%;
}

.goToSongonDescription{
    background-color: #fff;
    width: 80%;
    border: 1px dashed #00afb9;
    color: #333;
    text-align: center;
    font-size: 12px;
    padding: 10px 0;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.goToSongonButton{
    background-color: #00afb9;
    width: 20%;
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 10px 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.songonContainer{
    margin-bottom: 15px;
}

.isJoin{
    background-color: #ffc000;
    color: #ff0000;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0.25rem;
    padding: 2px 10px;
}

.songonListCard{
    background-color: #f5f6fa;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.titleColor{
    color: rgb(43, 42, 42);
}

.songonListContainer{
    max-height: 270px;
    overflow-y: auto;
}

.borderBottom{
    border-bottom: 1px dashed #cfbbbb;
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.songonName{
    color: #0b9aa7;
    margin-top: 18px;
    margin-bottom: 18px;
    padding-right: 10px;
    cursor: pointer;
    text-decoration-line: underline;
}

.fieldName{
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 10px 0;
    border-right: 1px dashed #ddd
}

.valueName{
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 10px 0;
    padding-left: 10px;
}

.columnOne{
    display: flex;
    width: 33%;
}

.columnTwo{
    display: flex;
    width: 67%;
}

.empty{
    background-color: #f5f6fa;
    font-size: 12px;
    padding: 10px 0;
    text-align: center;
    color: #484848;
    border-radius: 8px;
}

@media (max-width: 992px) {
    .row{
        margin-top: 0;
    }
    .companyInfo{
        margin-top: 15px;
    }
    .planImage{
        height: 250px;
    }
    .miniCard{
        margin-top: 15px;
    }
    .songonContainer{
        margin-top: 15px;
    }
    .columnOne{
        width: 50%;
    }

    .columnTwo{
        width: 50%;
    }
}
