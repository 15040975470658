.todaySignWrapper{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.todaySignContainer {
    display: flex;
}


.todayAccessItem{
    border-radius: 8px;
    margin-top: 10px;
    padding: 20px 12px;
    display: flex;
    justify-content: space-between;
}


.titleIcon{
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.titleIcon i {
    font-size: 25px;
    color: rgb(255, 255, 255);
    margin-right: 10px;
}

.titleIcon span{
    font-size: 13px;
    color: #333;
}

.accessNumber{
    font-size: 24px;
}

.todayTitle{
    font-size: 14px;
    font-weight: 500;
    color: rgb(54, 54, 54);
}


/******************** COPY FROM SUB AGENDA ********************/
.numberWithPercent{
    text-align: right;
}

.numberWithPercent .number{
    font-size: 16px;
}

.numberWithPercent .percent {
    font-size: 11px;
    color: #005B9A
}

@media (max-width: 992px) {
    .customBottom{
        margin-bottom: 10px;
    }
}
