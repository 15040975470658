.noti .dropdown-menu{
    min-width: 300px !important;
    height: auto !important;
    /* min-height: 600px !important; */
    overflow-x: auto;
}

.noti .dropdown-item:hover{
    background: none !important;
}

.noti .dropdown-item {
    padding: 0px 5px !important;
}