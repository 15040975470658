.mySwiper .swiper-pagination .swiper-pagination-bullet{
    background-color: rgb(194, 194, 194);
}
.mySwiper .swiper-pagination .swiper-pagination-bullet-active{
    background-color: #525252;
}
.swiper-pagination{
    bottom: 0 !important;
}
.swiper {
    padding-bottom: 25px !important;
}

.mySwiper .swiper-button-next {
    width: 25px;
    height: 25px;
    background-color: #00AFB9;
    /* #00AFB9 */
    border-radius: 50%;
}
.mySwiper .swiper-button-next::after {
    font-size: 11px;
    text-transform: none!important;
    color: white;
    font-weight: 600;
}
.mySwiper .swiper-button-prev {
    width: 25px;
    height: 25px;
    background-color: #00AFB9;
    /* #00AFB9 */
    border-radius: 50%;
}
.mySwiper .swiper-button-prev::after {
    font-size: 11px;
    text-transform: none!important;
    color: white;
    font-weight: 600;
}
