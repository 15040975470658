.col{
    padding: 0 10px ;
}

.gridCard{
    padding: 20px;
    padding-bottom: 30px;
    box-shadow: 0 3px 8px 0 #d9e2ec;
    border-radius: 0px;
    background-color: #fff;
    margin-bottom: 20px;
    position: relative;
    height: 400px;
}

.imageContainerZ{
    display: flex;
    justify-content: center;
    height: 250px;
}

.image{
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
}

.imageContainerList{
    display: flex;
    justify-content: center;
    max-height: 200px;
    max-width: 200px;
}

.imageList{
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
}

.newsTitle{
    color: #405189;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 17.5px;
    cursor: pointer;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.newsSmallText{
    color: #0b9aa7;
    font-size: 12px;
    margin-right: 10px;
    margin-top: 5px;
}

.txBlue{
    margin-right: 2px;
    color: #144273;
}

.listView{
    background-color: #fff;
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
    min-height: 150px;
    max-height: 220px;
}

.imageDesc{
    align-items: center;
    display: flex;
}

.textContainer{
    padding: 10px;
    width: 100%;
}

.titleList{
    color: #405189;
    font-weight: 500;
    font-size: 17.5px;
    margin-top: 10px;
    cursor: pointer;
}

.imageDesc .desc{
    margin-top: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-align: justify;
}

.numberContainer{
    position: absolute;
    bottom: 10px;
    display: flex;
}

.readMore{
    text-decoration: underline;
    font-size: 12px;
    color: #405189;
    transition: 0.3s;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.readMore i {
    position: absolute;
    top: 25%;
    right: -5px;
    font-size: 10px;
    opacity: 0;
    transition: 0.3s;
}

.gridDesc{
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.readMore:hover i{
    opacity: 1;
    transition: 0.3s;
    right: -10px;
}



@media only screen and (max-width: 768px) {
    .gridCard{
        height: unset;
    }
}

@media only screen and (max-width: 1200px) {
    .gridCard{
        height: 330px;
    }
    .imageContainerZ{
        height: 175px;
    }
}
