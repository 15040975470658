.loginBanner{
    height: 100vh;
    background-image: url(../../../public/images/shono_small.png) ;
    background-size: cover;
}

.roleWrapper{
    padding: 30% 0;
}

.loginSection{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.loginSection input{
    padding: 11px;
    background-color: #fff;
}

.loginSection input::placeholder {
    color: #7b8490;
    font-size: 13px;
}

.loginSection button{
    padding: 12px;
    border-radius: 24px;
    width: 100%;
    margin-top: 3px;
    font-size: 14px !important
}

.loginSection h3{
    font-weight: 400;
}

.loginSection label{
    text-transform: uppercase;
    font-size: 12.5px;
}

.loginSection a{
    color: #005b9a;
    font-size: 12px;
    display: block;
    margin-top: 9px;
}

.signupSection{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.signupSection input{
    padding: 9px;
    background-color: #fff;
}

.signupSection button{
    padding: 12px;
    border-radius: 24px;
    width: 100%;
    margin-top: 3px;
    font-size: 14px !important
}

.signupSection h3{
    font-weight: 400;
}

.signupSection label{
    text-transform: uppercase;
    font-size: 12.5px;
}

.signupSection h4{
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
}

.signTypeTitle{
    text-transform: uppercase;
}

.passReset{
    width: 400px;
}

.goCorner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #0191c8;
    border-radius: 0 4px 0 32px;
}

.goArrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
}

.card1 {
    display: block;
    position: relative;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 47px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    box-shadow: 0 3px 8px 0 #d9e2ec;
    width: 300px;
    cursor: pointer;
}

.card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #0191c8;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.card1:hover:before {
    transform: scale(22);
}

.card1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}

.card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}

.card1 i{
    color: #0191c8;
    font-size: 40px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px
}

.card1:hover i{
    color: #fff;
}

.roleTitle{
    color: #222b3b !important;
    text-align: center;
    font-size: 17px;
}

.customCenter{
    justify-content: center;
}

@media (max-width: 500px){
    .loginSection img{
        width: 100%;
    }
    .passReset{
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .loginSection{
        padding: 30% 16px !important;
    }
    .signupSection{
        padding: 30% 16px !important
    }
    .card1{
        width: 100% !important;
    }
}

@media (max-width: 992px) {
    .loginSection img{
        display: none;
    }
    .signupSection img{
        display: none;
    }
    .loginBanner{
        height: 100%;
    }
    .customCenter{
        justify-content: center;
    }
}

@media (max-width: 1200px){
    .loginSection{
        padding: 30% 0
    }
    .signupSection{
        padding: 30% 10px
    }
}
