.container{
    position: fixed;
    width: 260px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 1041;
    box-shadow: 10px 0 10px -10px #d3d7e9;
}

.show{
    transition: 0.2s !important;
    left: 0 !important
}

.hide{
    transition: 0.2s !important;
    left: -260px !important
}

.companyInfo{
    display: flex;
    justify-content: center;
    background-color: #F5F6FA;
    text-align: center;
}

.container .logo{
    display: flex;
    align-items: center;
    padding: 17px;
    transition: 0.2s;
}

.companyImage{
    padding: 25px;
}

.companyImage img{
    border-radius: 50%;
    border: 2px solid #005b9a;
    width: 72px;
    padding: 2px
}

.logo{
    cursor: pointer;
}

.logo img{
    width: 100%;
}

.sidebarIcon{
    width: 10px;
    display: flex;
    text-align: center;
}

.navActive{
    background: #f5f6fa !important;
    transition: 0.2s !important;
}

.navSubItem {
    padding: 10px 10px 10px 30px;
    position: relative;
    margin-bottom: 1px;
    color: #545454 !important;
    font-weight: 400;
    text-decoration: none;
    font-size: 13px;
    font-family: "Nunito", sans-serif !important;
    width: 100%;
    transition: 0.2s !important;
}


.navSubItem::before{
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    top: 40%;
    border-radius: 50%;
    left: 17px;
    background-color: #0ec6d5;
    transition: 0.2s !important;
    box-shadow: -3px 0px 20px 0px rgba(14,198,213,1);
    -webkit-box-shadow: -3px 0px 20px 0px rgba(14,198,213,1);
    -moz-box-shadow: -3px 0px 20px 0px rgba(14,198,213,1);
    opacity: 0;
}

.navSubItem:hover{
    background-color: #f5f6fa57  !important;
}


.navSubItemActive {
    background-color: #f5f6faab  !important;
}

.navSubItemActive:hover{
    background-color: #f5f6faab  !important;
}

.navSubItemActive::before{
    opacity: 1;
}

.navItem{
    padding: 10px 20px;
    position: relative;
    margin-bottom: 1px;
    color: #545454 !important;
    font-weight: 400;
    text-decoration: none;
    font-size: 14px;
    font-family: "Nunito", sans-serif !important;
    width: 100%;
    transition: 0.2s !important;
}

.navItem:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background-color: #0ec6d5;
    transition: 0.2s !important;
    box-shadow: -3px 0px 20px 0px rgba(14,198,213,1);
    -webkit-box-shadow: -3px 0px 20px 0px rgba(14,198,213,1);
    -moz-box-shadow: -3px 0px 20px 0px rgba(14,198,213,1);
    opacity: 0;
}

.navItem:hover{
    background: #f5f6fa6b;
    transition: 0.2s !important;
    color: unset;
}

.navItem:hover::before{
    background-color: #0ec4d577;
    opacity: 1;
    transition: 0.2s !important;
    box-shadow: -3px 0px 15px 0px rgba(14, 196, 213, 0);
    -webkit-box-shadow: -3px 0px 15px 0px rgba(14, 196, 213, 0);
    -moz-box-shadow: -3px 0px 15px 0px rgba(14, 196, 213, 0);
}
.navActive::before{
    opacity: 1;
    transition: 0.2s !important;
}

.navActive:hover::before{
    background-color: #0ec6d5;
    box-shadow: -3px 0px 15px 0px rgba(14,198,213,1);
    -webkit-box-shadow: -3px 0px 15px 0px rgba(14,198,213,1);
    -moz-box-shadow: -3px 0px 15px 0px rgba(14,198,213,1);
}

.sideBarTitle{
    padding: 10px 20px;
    color: #000;
    font-weight: 500;
    font-size: 12px;
}

.navItemSub{
    background-color: #DEDEDE;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.3s ;
    -moz-transition: max-height 0.3s ;
    -o-transition: max-height 0.3s ;
    -ms-transition: max-height 0.3s ;
    transition: max-height 0.3s ;
}

.subMenuIcon{
    position: absolute;
    right: 0;
    transition: 0.2s;
}

.navItemContainer{
    transition: 0.2s !important;
}

.buttons{
    display: flex;
    justify-content: space-around;
}

.buttons div{
    width: 38px;
    height: 38px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setting{
    cursor: pointer;
    color: #fff;
    background-color: #005b9a;
    margin-right: 5px;
    font-size: 16px;
}

.power{
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    background-color: #FF3E3E;
}

.closeContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.close{
    top: 68px;
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #005b9a;
    font-size: 16px;
    transition: 0.3s;
}

/* .close:hover{
    transition: 0.3s;
    background-color: rgb(143, 3, 143);
    box-shadow: -0 -0 15px 0 rgba(183,13,224,0.52);
    -webkit-box-shadow: -0 -0 15px 0 rgba(183,13,224,0.52);
    -moz-box-shadow: -0 -0 15px 0 rgba(183,13,224,0.52);
} */


.sideBarbody2{
    overflow-y: auto;
    height: calc(calc(100vh - calc(100vh - 100%)) - 100px);
}

.sideBarbody{
    padding: 15px 0;
    overflow-y: auto;
    height: calc(calc(100vh - calc(100vh - 100%)) - 201px - 61px - 28px);
}

.sidebarHeader{
    height: 60px;
    width: 100%;
    background-color: #fff;
    padding-left: 300px;
    padding-right: 40px;
    transition: 0.2s;
    display: flex;
    top: 0;
    position: fixed;
    z-index: 3;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 10px 10px -10px #d3d7e9;
}

.sidebarHeader .dropdownIcon{
    font-size: 30px;
    cursor: pointer;
    color: #005b9a;
}

.sidebarHeader button::after{
    content: none;
}

.sidebarFooter{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 260px;
    background-color: #005b9a;
    color: #fff;
    position: absolute;
    bottom: 0;
    z-index: 2000;
    font-size: 12.5px;
}

.headerTitle{
    font-size: 15.5px;
    font-weight: 500;
}

@media (max-width: 800px)  {
    .container{
        left: -260px;
        box-shadow: none;
    }
    .sidebarHeader{
        padding-left: 30px;
    }
    .sidebarFooter{
        display: none;
    }
}
