.imgContainer {
    height: 350px;
    border: 1px dashed rgb(189, 189, 189);
    border-radius: 5px;
    background-color: #eeeeee;
    padding: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.imgContainer img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.imgContainer div{
    color: grey;
}

.imgContainer[disabled] {
    cursor: unset;
}

.thumContainer{
    background-color: #d3d3d300;
    padding: 5px;
    max-height: 350px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.imageWrapper{
    position: relative;
    padding: 2px;
}

.deleteIcon{
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 1;
    cursor: pointer;
}

.thumImage{
    cursor: pointer;
    height: 125px;
    width: 100%;
    opacity: 0.7;
    transition: 0.3s;
}

.thumImage:hover{
    opacity: 1;
}

.thumListActiveItem {
    opacity: 1 !important;
}

.dragAndDrop{
    text-align: center;
    width: 50%;
}

.dragAndDrop span{
    font-size: 15px;
}

.dragAndDrop i{
    font-size: 50px;
}

.activeDragAndDrop{
    border: 1px solid rgb(155, 155, 155);
}

.chooseItemContainer{
    border: 1px solid #005B9A !important;
    background-color: #ffffff !important;
    display: flex;
    border-radius: 5px;
    width: fit-content;
}
.choosedItem{
    background-color: #005B9A !important;
    color: #fff;
}

.chooseItem:last-child{
    border-right: unset;
}

.chooseItem{
    padding: 7px 10px;
    cursor: pointer;
    border-right: 1px solid #005B9A;
}

@media (max-width: 768px) {
    .imgContainer{
        height: 300px;
    }
}

@media (max-width: 995px) {
    .thumContainer{
        margin-top: 5px;
        background-color: #d3d3d300;
        padding: 5px;
        max-height: 150px !important;
        display: flex;
        flex-wrap: unset;
        overflow-x: scroll;
    }
}
