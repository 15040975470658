.searchField{
    position: absolute;
    z-index: 999 !important;
    /* background-color: #f5f6fa; */
    left: 0;
    right: 0;
    height: 71px;
}

.searchMainInputs{
    padding: 8px 8px;
    padding-right: 60px;
    background-color: white;
    border-radius: 25px;
    opacity: 1;
    border: 2px solid white;
    position: relative;
    width: 100%;
    transition: all 0.25s ease-in-out;
    height: 52px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
}

.filters{
    position: relative;
    padding: 8px 8px;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    border-radius: 3px;
    top: 5px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    left: 0;
}

.oneFilterItem{
    padding: 5px;
    margin-right: 5px;
    margin-top: 2px;
    border: 1px solid #ccc;
    border-radius: 18px;
    font-size: 14px;
    padding-left: 10px;
    /* background-color: #00afb9; */
    color: #333;

}

.oneFilterItem i{
    color: #c3c6d1;
    cursor: pointer;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 13px;
}

.oneFilterItem i:hover{
    color: #ff3e3e;
}

.moreSearchButton{
    background-color: #fff;
    border-radius: 52px;
    color: #222b3b!important;
    font-size: 12.5px;
    font-weight: 400;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    height: 40px;
}

.circleSmall{
    background-color: #005b9a;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-left: 20px;
    color: #fff !important;
    line-height: 30px;
    -webkit-box-shadow: 0px 0px 20px -10px #000000;
    box-shadow: 0px 0px 20px -10px #000000;
}

.moreSearchButton:hover div{
    transition: 0.3s;
    background-color: #0191c8;
}

.mainSearchButton{
    background-color: #0191c8;
    border-radius: 50%;
    border: none;
    padding: 10px;
    margin: 0px;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s;
    color: #fff;
}

.mainResetButton{
    cursor: unset !important;
    border: none;
    background-color: unset;
    width: 35px;
    height: 35px;
    box-sizing: border-box;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
    color: #FF3E3E;
}

.mainResetButton i {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.gg{
    display: none;
}

.circleSmallMobile{
    background-color: #005b9a;
    border-radius: 50%;
    height: 30px;
    align-items: center;
    width: 30px;
    color: #fff !important;
    line-height: 30px;
    -webkit-box-shadow: 0px 0px 20px -10px #000000;
    box-shadow: 0px 0px 20px -10px #000000;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 7px;
}

.mainSearchButton:hover{
    color: #fff;
    background-color: #005b9a;
}

.mainSearchButtonMobile{
    padding: 0;
}

@media (max-width: 992px){


    .searchField{
        height: 67px;
        margin-top: 62px !important
    }
    .moreSearchButton{
        display: none !important;
    }
    .gg{
        display: block !important;
        margin: 0 auto
    }
    .oneFilterItem{
        font-size: 12px;
    }
}

@media (max-width: 768px){
    .filters{
        overflow: auto;
        max-height: 80px;
        top: 5px
    }

    .searchMainInputs{
        padding-right: 0;
        padding: 5px 8px;
        background-color: white;
        border-radius: 25px;
        opacity: 1;
        border: 2px solid white;
        position: relative;
        width: 100%;
        transition: all 0.25s ease-in-out;
        height: 45px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    }
}
