.item {
    padding: 10px;
    display: flex;
    flex-direction: row;
    border: 1px solid gray;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
.item:hover {
    background-color: #ccc;
    transition: 0.2s;
}
.zurag {
    margin-right: 10px;
    width: 50;
    height: 50;
    object-fit: contain;
    border-radius: 20%;
}
.itemInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}
.location {
    display: flex;
    justify-content: start;
    font-size: 10px;
}

.pItem {
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pItem:hover {
    background-color: #ccc;
}
.pItem.active {
    color: white;
    background-color: #0191c8;
    border: 1px solid #0191c8;
    transition: .3s;
}
