.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}

.strike > .body {
    position: relative;
    display: inline-block;
}

.strike > .body:before,
.strike > .body:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #d5dee2;
}

.strike > .body:before {
    right: 100%;
    margin-right: 15px;
}

.strike > .body:after {
    left: 100%;
    margin-left: 15px;
}
